import SearchInput from "../SearchInput"

const StepOne = props => {

  return(
    <div className="StepOne_Container">
      <p className="Step_Header">Enter Name on Reservation</p>
      <SearchInput Search={props.Search} SearchText={props.SearchText} />
      
    </div>
  )
}

export default StepOne; 