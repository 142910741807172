const PersonListItem = props => {

  const HandleClick = () => {
    props.GetFamily(props.Person)
  }

  return(
    <div className="PersonListItem_Container">
      <div className="PersonListItem" onClick={HandleClick}>
        {props.Person.LastName}, {props.Person.FirstName}
      </div>
    </div>
  )
}

export default PersonListItem; 