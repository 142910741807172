import SearchInput from "./SearchInput"
import RSVPPersonList from "./RSVPPersonList"
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
const StepThree = props => {
  
  const HandleSaveClick = () => {
    props.SaveRSVPs()
  }

  return(
    <div className="StepThree_Container">
      <p className="Step_Header">Enter Name on Reservation</p>
      <SearchInput Search={props.Search} SearchText={props.SearchText} GoBack={props.GoBack} ShowBackButton={true}/>
      {props.ErrorText !== "" && <div className="ErrorContainer"><p className="ErrorText"><FontAwesomeIcon icon={faXmarkCircle} className="ErrorTextIcon" /> {props.ErrorText}</p></div>}
      <RSVPPersonList PersonList={props.PersonList} Meals={props.Meals} StoreRSVPs={props.StoreRSVPs} RSVPs={props.RSVPs} />
      <Button onClick={HandleSaveClick} className="SaveRSVP_Button" disabled={props.ErrorText !== ""}>Save RSVP</Button>
    </div>
  )
}

export default StepThree; 