const StepFour = props => {

  return (
    <div className="StepFour_Container">
      <p className="Step_Header">RSVP Submitted</p>
      <div className="StepFour_Text_Container">
        <p >Thank your for RSVP'ing!</p>
        <p className="StepFour_Text">{props.ConfirmationText}</p>
      </div>
    </div>
  );
}

export default StepFour; 