import PersonListItem from './PersonListItem'

const PersonList = props => {

  return(
    <div className="PersonList_Container">
      {props.PersonList.length > 0  
        ? props.PersonList.map(Person => <PersonListItem Person={Person} key={Person.ID} GetFamily={props.GetFamily} />)
        : <div className="PersonList_NoResults"><p>No names found. Please search again.</p></div>}
    </div>
  )
}

export default PersonList; 