import RSVPPersonListItem from "./RSVPPersonListItem";

const RSVPPersonList = props => {

  return (
    <div className="RSVPPersonList_Container">
      {props.PersonList.map((Person) => {
          return (
            <RSVPPersonListItem
              Person={Person}
              key={Person.ID}
              Meals={props.Meals}
              StoreRSVPs={props.StoreRSVPs}
            />
          );
      }, props)}
    </div>
  );
};

export default RSVPPersonList;
