import { React, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import FormCheck from "react-bootstrap/FormCheck";
import FormControl from "react-bootstrap/FormControl"

const RSVPPersonListItem = (props) => {
  const [RSVPed, SetRSVPed] = useState("");
  const [MealID, SetMealID] = useState(null);
  const [GuestFirstName, SetGuestFirstName] = useState("");
  const [GuestLastName, SetGuestLastName] = useState("");

  const CheckedAttribute = RSVPed === "N" ? {checked: false} : {}

  const HandleClick = (Type, PersonID) => (e) => {
    let Key,
      Value = "";
    if (Type === "r") {
      Key = "RSVPed";
      const Target = e.target.localName === "path" ? e.target.parentElement : e.target;
      Value = JSON.parse(Target.dataset.value);
      SetRSVPed(Value === true ? "Y" : "N")
      Value === false && SetMealID(null);
    } else if (Type === "m") {
      Key = "MealID";
      Value = JSON.parse(e.target.value);
      SetMealID(Value)
    } else if (Type === "g") {
      Key = "IsGlutenFree";
      Value = JSON.parse(e.target.value);
    }
    if (Type === "r" && Value === false){
      props.StoreRSVPs({ ID: props.Person.ID, MealID: null });
    }
    props.StoreRSVPs({ ID: props.Person.ID, [Key]: Value });
  };

  const HandleChange = (Type) => (e) => {
    let Key,
      Value = "";
    if(Type === "First"){
      Key="FirstName"
      Value = e.target.value
      SetGuestFirstName(Value);
    }else if(Type==="Last"){
      Key="LastName"
      Value = e.target.value
      SetGuestLastName(Value);
    }
    props.StoreRSVPs({ ID: props.Person.ID, [Key]: Value });
  }

  const PersonID = props.Person.ID;
  const IsGuest = props.Person.IsGuest

  return (
    <div className="RSVPPersonListItem_Container">
      <div className="RSVPPersonListItem">
        {/* 
        <FontAwesomeIcon icon={faTimesCircle} /> */}
        <div className="RSVPPersonListItem_HeaderRow">
          <div className="RSVPPersonListItem_RSVPButtons">
            <FontAwesomeIcon
              icon={faCircleCheck}
              data-inputid={`RSVP-Y-${PersonID}`}
              className={`RSVPPersonListItem_RSVPButton RSVPPersonListItem_RSVPButton_Yes ${
                RSVPed === "Y" ? "Selected" : ""
              }`}
              onClick={HandleClick("r", PersonID)}
              data-value="true"
            />
            <FontAwesomeIcon
              icon={faTimesCircle}
              data-inputid={`RSVP-N-${PersonID}`}
              className={`RSVPPersonListItem_RSVPButton RSVPPersonListItem_RSVPButton_No ${
                RSVPed === "N" ? "Selected" : ""
              }`}
              onClick={HandleClick("r", PersonID)}
              data-value="false"
            />
          </div>
          <div className="RSVPPersonListItem_Name">
            <p>
              {!IsGuest && `${props.Person.FirstName} ${props.Person.LastName}`}
              {IsGuest &&
                `${props.Person.ParentFirstName} ${props.Person.ParentLastName},'s Plus One`}
            </p>
          </div>
        </div>
        {IsGuest && (
          <div className="RSVPPersonListItem_GuestName">
            <div className="RSVPPersonListItem_GuestName_First">
              <label>First Name</label>
              <FormControl
                type="text"
                data-inputid={`RSVP-Guest-FirstName-${PersonID}`}
                onChange={HandleChange("First")}
                value={GuestFirstName}
              />
            </div>
            <div className="RSVPPersonListItem_GuestName_Last">
              <label>Last Name</label>
              <FormControl
                type="text"
                data-inputid={`RSVP-Guest-LastName-${PersonID}`}
                onChange={HandleChange("Last")}
                value={GuestLastName}
              />
            </div>
          </div>
        )}
        <div className="RSVPPersonListItem_FoodRow">
          {props.Meals.map((Meal) => (
            <FormCheck
              key={`M-${Meal.ID}-${PersonID}`}
              value={Meal.ID}
              type="radio"
              id={`M-${Meal.ID}-${PersonID}`}
              label={Meal.Name}
              name={`Meal-${PersonID}`}
              onClick={HandleClick("m", PersonID)}
              className={`RSVPPersonListItem_FoodRow_Item ${
                RSVPed === "N" ? "Disabled" : ""
              } ${MealID !== Meal.ID && MealID !== null ? "NotSelected" : ""}`}
              disabled={RSVPed === "N"}
              {...CheckedAttribute}
            />
          ))}
          {/* <FormCheck
            key={`M-G-${PersonID}`}
            type="checkbox"
            id={`M-G-${PersonID}`}
            label="Gluten Free"
            onClick={HandleClick("g", PersonID)}
            value="true"
            className="RSVPPersonListItem_FoodRow_Item RSVPPersonListItem_FoodRow_GlutenFreeItem"
            disabled={RSVPed === "N"}
            {...CheckedAttribute}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default RSVPPersonListItem;
