import { useState } from "react";
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const SearchInput = props => {

  const [SearchText, SetSearchText] = useState(props.SearchText);

  const HandleChange = (event) => {
    const value = event.target.value;
    SetSearchText(value);
  };

  const HandleClick = () => { 
    props.Search(SearchText)
  }

  const HandleBackClick = () => {
    props.GoBack()
  }

  const HandleKeyDown = e => e.key === 'Enter' && HandleClick()

  return(
    <div className="SearchInput_Container">
      {props.ShowBackButton === true ? <Button onClick={HandleBackClick} className="SearchInput_BackButton"><FontAwesomeIcon icon={faArrowLeft} /></Button> : ""}
      <input onChange={HandleChange} value={SearchText} className="SearchInput_Input" onKeyDown={HandleKeyDown}/>
      {/* {props.ShowBackButton === true ? <Button onClick={HandleBackClick} className="SearchInput_BackButton_Mobile"><FontAwesomeIcon icon={faArrowLeft} /></Button> : ""} */}
      <Button onClick={HandleClick} className="SearchInput_Button">Find RSVP</Button>
    </div>
  )
}

export default SearchInput