import SearchInput from "./SearchInput"
import PersonList from "./PersonList"

const StepTwo = props => {

  return(
    <div className="StepTwo_Container">
      <p className="Step_Header">Enter Name on Reservation</p>
      <SearchInput Search={props.Search} SearchText={props.SearchText} />
      <PersonList PersonList={props.PersonList} GetFamily={props.GetFamily} />
    </div>
  )
}

export default StepTwo; 