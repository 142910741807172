import './App.css';
import { useState, useEffect } from "react";
import StepOne from "./Components/StepOne/StepOne"
import StepTwo from "./Components/StepTwo"
import StepThree from "./Components/StepThree"
import StepFour from "./Components/StepFour"

function App() {
  const [Step, SetStep] = useState(1)
  const [SearchText, SetSearchText] = useState("")
  const [PersonList, SetPersonList] = useState([])
  const [RSVPs, SetRSVPs] = useState({})
  const [InviteList, SetInviteList] = useState({})
  const [MealList, SetMealList] = useState({})
  const [ErrorText, SetErrorText] = useState("")


  const LoadData = async () => {
    const InviteResponse = await fetch("https://bronzos.com/api.php/person/list?IncludeRSVPed=false", {method: 'GET'});
    const InviteResponseJson = await InviteResponse.json()
    const LastUsedID = InviteResponseJson[InviteResponseJson.length - 1].ID;
    let TempGuestID = LastUsedID + 1;
    InviteResponseJson.forEach(Person => {
      if(Person.AllowGuest === 1 && Person.GuestID === null){
        InviteResponseJson.push({
          "ID": TempGuestID, "FamilyID": Person.FamilyID, "FirstName": "", "LastName": "", "RSVPed": null, "Meal": null, "SpecialPrivilege": 0, "AllowGuest": 0, "GuestID": null, "TableNumber": 0, "Notes": "", "RSVPDateTime": "", "GiftReceived": "", "AddressID": 0, "EmailID": 0, "RSVPType": null, "IsGlutenFree": 0, "IsGuest": true, "ParentID": Person.ID, "ParentFirstName": Person.FirstName, "ParentLastName": Person.LastName
        })
        TempGuestID++
      }
    })
    SetInviteList(InviteResponseJson);


    const MealResponse = await fetch("https://bronzos.com/api.php/meal/list", {method: 'GET'});
    SetMealList(await MealResponse.json());
  }

  const SaveRSVPsData = async () => {
    const SaveRSVPsResponse = await fetch("https://bronzos.com/api.php/person/update",
      {
        method: 'POST',
        body: JSON.stringify(RSVPs)
      }
    ).then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
    return await SaveRSVPsResponse
  }

  useEffect(() => {
    LoadData();
    return () => {};
  }, []);

  // const ConfirmationText =
  //   "Please contact Brielle if you need to make any changes to your RSVP. Our website should have everything you need regarding the wedding, but please reach out if you have any questions or concerns.<br><br>If you are traveling from out of town, we have a block of rooms at the Visalia Marriott at the Convention Center. The rooms will be released on Thursday, September 14th 2023. Please book by then by clicking <a href='https://www.marriott.com/event-reservations/reservation-link.mi?id=1684881329005&key=GRP&app=resvlink'>Here</a> or you can find more info by clicking Accommodations in the menu above.<br><br>We can't wait to celebrate with you all in October!";

      const ConfirmationText =
        "Please contact Brielle if you need to make any changes to your RSVP. Our website should have everything you need regarding the wedding, but please reach out if you have any questions or concerns. If you are traveling from out of town, we have a block of rooms at the Visalia Marriott at the Convention Center. The rooms will be released on Thursday, September 14th 2023. Learn more by clicking TRAVEL in the menu above. We can't wait to celebrate with you all in October!";

  const Search = SearchTextInput => {
    //search for names based on input
    SetSearchText(SearchTextInput)
    SetPersonList(SearchInviteList(SearchTextInput))
    SetStep(2)
  }

  const SearchInviteList = SearchTextInput => {
    const SearchText = SearchTextInput.toLowerCase().trim().split(" ")
    let PersonList = []
    if(SearchText[0] !== ""){
      PersonList = InviteList.filter(Invitee => {
        return SearchText.some(s => {
          return Invitee.FirstName.toLowerCase().includes(s) || Invitee.LastName.toLowerCase().includes(s)
        })
      })
    }
    return PersonList
  }

  const GetFamily = SelectedPerson => {
    const FamilyData = InviteList.filter(Person => Person.FamilyID === SelectedPerson.FamilyID)
    SetPersonList(FamilyData)
    SetRSVPs(FamilyData.map(p => ({ID: p.ID, FamilyID: p.FamilyID, RSVPed: null, RSVPType: 1, MealID: null, IsGlutenFree: false, IsGuest: (p.IsGuest === undefined ? false : p.IsGuest), FirstName: p.FirstName, LastName: p.LastName, ParentID: (p.ParentID !== undefined ? p.ParentID : null)})))
    SetStep(3)
  }

  const GoBack = () => {
    if(Step - 1 < 3){
      SetPersonList(SearchInviteList(SearchText))
    }
    SetStep(Step - 1)
  }

  const StoreRSVPs = FormRSVP => {
    ClearErrorText()
    SetRSVPs(RSVPs.map(obj => {
      if(obj.ID === FormRSVP.ID){
        const Key = Object.keys(FormRSVP).find(k => k !== 'ID')
        if (Key === "RSVPed" && FormRSVP[Key] === false) {
          Object.assign(obj, { [Key]: FormRSVP[Key] });
          Object.assign(obj, { MealID: null });
          return obj;
        } else {
          return { ...obj, [Key]: FormRSVP[Key] };
        }

      }
      return obj
    }))
  }

  const ValidateRSVPs = () => {
    let Error = "";
    const RSVPsTemp = RSVPs;
    for(const R of RSVPs){
      console.log(R)
        if(R.RSVPed === null && R.IsGuest === false){
          Error = `${R.FirstName} ${R.LastName} did not make an RSVP selection. Please select yes or no.`
          break;
        } else {
          if(R.IsGuest === true && R.RSVPed !== false){
            let BreakR = false;
            for (const Rs of RSVPsTemp) {
              if(R.RSVPed === null){
                Error = `${Rs.FirstName} ${Rs.LastName}'s Plus One did not make an RSVP selection. Please select yes or no.`;
                BreakR = true;
                break;
              }
              if (R.ParentID === Rs.ID && Rs.RSVPed !== true) {
                Error = `${Rs.FirstName} ${Rs.LastName}'s Plus One can not be RSVPed if ${Rs.FirstName} ${Rs.LastName} has RSVPed no.`;
                BreakR = true;
                break;
              }
               if(R.FirstName === ""){
                Error = `${Rs.FirstName} ${Rs.LastName}'s Plus One did not enter their first name. Please enter their first name.`;
                BreakR = true;
                break;
              }
              if(R.LastName === ""){
                Error = `${Rs.FirstName} ${Rs.LastName}'s Plus One did not enter their last name. Please enter their first last.`;
                BreakR = true;
                break;
              }
            }
            if(BreakR){
                break;
            }
             if(R.MealID === null){
              Error = `${R.FirstName} ${R.LastName} did not make a meal selection. Please select your meal option.`
              break;
            }
          }
          if(R.MealID === null && R.RSVPed === true){
            Error = `${R.FirstName} ${R.LastName} did not make a meal selection. Please select your meal option.`
            break;
          }
        }
      }
      SetErrorText(Error)
      return Error === "" ? true : false;
    }

    const ClearErrorText = () => SetErrorText("")


  const SaveRSVPs = async () => {
    if(ValidateRSVPs()){
      await SaveRSVPsData()
      SetStep(4)
    }
  }


  
  return (
    <div className="App" id="RSVPApp">
      {Step === 1 ? <StepOne Search={Search} SearchText={SearchText} /> : null}
      {Step === 2 ? (
        <StepTwo
          Search={Search}
          SearchText={SearchText}
          PersonList={PersonList}
          GetFamily={GetFamily}
        />
      ) : null}
      {Step === 3 ? (
        <StepThree
          Search={Search}
          SearchText={SearchText}
          PersonList={PersonList}
          GoBack={GoBack}
          Meals={MealList}
          StoreRSVPs={StoreRSVPs}
          SaveRSVPs={SaveRSVPs}
          RSVPs={RSVPs}
          ErrorText={ErrorText}
        />
      ) : null}
      {Step === 4 ? <StepFour ConfirmationText={ConfirmationText} /> : null}
    </div>
  );
}

export default App;
